// @ts-ignore
import merge from 'lodash/merge';
import { CSSProperties } from 'react';

import { MailingAddress } from '../../types/geo';
import { AppSettings } from '../../types/users';
import utils from '../../helpers/utils';
import walletStyles, { defaultColors, defaultDividerColor } from './assets/walletStyles';
import legacyWalletStyles from './assets/legacyWalletStyles';

// @ts-ignore
const misspelledStyles: { [key: string]: string } = {
	horizonalPadding: 'paddingHorizontal',
	verticalPadding: 'paddingVertical',
};

const webStyleConversions: { [key: string]: (x: any) => any } = {
	paddingHorizontal: (x: number) => ({ paddingLeft: x, paddingRight: x }),
	paddingVertical: (x: number) => ({ paddingTop: x, paddingBottom: x }),
	borderColor: (x: string) => ({ border: `1px solid ${x}` }),
	fontFamily: (x: string) => ({ fontFamily: `${x} Roboto` }),
};

const themes: any = {
	aiqAlt: require('./assets/theme/aiq.json'),
	aiqMidnight: require('./assets/theme/aiqMidnight.json'),
	midnightGray: require('./assets/theme/midnightGray.json'),
	lemonade: require('./assets/theme/lemonade.json'),
	cookiesMontserrat: require('./assets/theme/cookiesMontserrat.json'),
	cookiesBarlow: require('./assets/theme/cookiesBarlow.json'),
};

const fmt = (x: any) => JSON.parse(JSON.stringify(x || {}));
export function buildStyle(newStyle: boolean, ss: any, storeID?: string | number) {
	// ! fix misspelled props and convert native app styles -> browser
	const recurFmt = (obj: any) => {
		for (let key of Object.keys(obj || {})) {
			const value = obj[key];
			if (typeof value === 'object') {
				obj[key] = recurFmt(value);
				continue;
			}
			if (key in misspelledStyles) {
				const newKey = misspelledStyles[key];
				obj[newKey] = value;
				key = newKey;
				delete obj[key];
			}

			if (key in webStyleConversions && !newStyle) {
				const fmtFunc = webStyleConversions[key],
					nVal = fmtFunc(value);
				if (typeof nVal === 'object') {
					obj = { ...obj, ...nVal };
					delete obj[key];
					continue;
				} else obj[key] = nVal;
			}
		}
		return obj;
	};

	if (storeID !== undefined && storeID != '-1' && (ss.storeStyles || {})[storeID]) {
		const storeStyles = (ss.storeStyles || {})[storeID];
		ss = merge(recurFmt(fmt(ss || {})), recurFmt(fmt(storeStyles || {})));
	}

	const theme: any = ss.theme && Object.keys(themes).includes(ss.theme) ? themes[ss.theme] : {},
		hasTheme = Object.keys(theme || {}).length > 0;
	if (theme) ss = { ...theme, ...ss };

	const colors = merge(
		{ ...fmt(defaultColors) },
		{
			color: ss?.config?.color,
			primary: ss?.config?.primary,
			secondary: ss?.config?.secondary,
			highlight: ss?.config?.highlight,
			error: ss?.config?.error,
			link: ss?.config?.link,
		},
	);

	const defaultText = {
		title: {
			color: '#191919',
			fontWeight: 600,
			fontSize: 16,
		},
		description: {
			color: '#191919',
			fontWeight: 400,
			fontSize: 14,
		},
		small: {
			color: '#191919',
			fontWeight: 400,
			fontSize: 12,
		},
	};

	const cfgText = ss?.config?.text;
	const text = {
		title: {
			...defaultText.title,
			...cfgText?.title,
		},
		description: {
			...defaultText.description,
			...cfgText?.description,
		},
		small: {
			...defaultText.description,
			...cfgText?.small,
		},
	};
	const dividerColor = ss?.config?.dividerColor || defaultDividerColor;
	const buttons = {
		button: ss?.config?.button,
		buttonSecondary: ss?.config?.buttonSecondary,
		buttonDanger: ss?.config?.buttonDanger,
	};

	const og = merge(
		recurFmt(fmt(newStyle ? walletStyles(fmt(colors), fmt(text), fmt(dividerColor), fmt(buttons), fmt(ss)) : legacyWalletStyles || {})),
		recurFmt(fmt(theme || {})),
	);
	const fin: AppSettings = merge(recurFmt(fmt(og || {})), recurFmt(fmt(ss || {})));

	if (!newStyle) {
		if (fin.theme === 'default' || fin.theme === 'newDefault') delete fin.theme;
		if (fin.p) fin.p = { ...fin.p, color: fin?.containerInternal?.color || fin?.container?.color };
		if (fin.mainBGColor === undefined && !fin.theme) fin.mainBGColor = '#f0f0f0';

		if (fin.containerInternal && !hasTheme)
			fin.containerInternal.border = fin.border || `${fin.containerInternal?.borderWidth || 1}px solid ${fin?.borderColor || '#6CD9A0'}`;
		if (fin.container) fin.container = { ...fin.container, background: fin.containerInternal?.backgroundColor || fin.container?.backgroundColor };
		if (fin.containerInternal)
			fin.containerInternal = { ...fin.containerInternal, background: fin.containerInternal.backgroundColor || fin.container?.backgroundColor };

		if (!fin.button?.borderRadius) fin.button = { ...fin.button };
		if (!fin.buttonDanger?.borderRadius) fin.buttonDanger = { ...fin.buttonDanger };
		if (!fin.buttonGhost?.borderRadius) fin.buttonGhost = { ...fin.buttonGhost };
	}

	return fin;
}

export const mergeThese = (a: any, b: any) => {
	const ab = fmt(a),
		bb = fmt(b);
	return merge(ab, bb);
};

// pickAny returns def if cond or all args are null
export function pickAny(cond?: any, def?: any, ...args: any) {
	if (!cond) for (const arg of args) if (arg) return arg;
	return def;
}

export function getAddr(addr?: MailingAddress) {
	if (!addr) return null;
	if (typeof addr == 'string') return addr;
	if (addr.street == '' && addr.zipCode == '') return null;
	addr.street = (addr.street || '')
		.split(' ')
		.map((x: any) => (isNaN(x) ? utils.capitalize(x) : x))
		.join(' ');
	return [addr.street, utils.capitalize(addr.city || ''), addr.state, addr.country, addr.zipCode].filter(Boolean).join(', ');
}

export async function setItemRating(uid: string, sku: string, cntID: string, review: number, dummy: boolean, method = 'POST') {
	return await utils.asyncFetch(
		async () => {
			if (!cntID || !uid) return null;
			if (sku && review && !dummy) {
				return utils.auth.legacyFetchWrap(`/api/v1/contact/review/${uid}/${cntID}`, method, { sku, review });
			}
		},
		(err: any) => null,
		false,
		true,
	);
}

export const sendWalletLink = (uid: string, body: { email?: string; phone?: string }) =>
	utils.asyncFetch(async () => {
		await utils.auth.post(`/request/wallet/${uid}`, body);
	});

// ! some styles from native app are not needed
export const unStyleButtons = (ss?: CSSProperties) => {
	ss = { ...ss };
	delete ss.display;
	delete ss.marginLeft;
	delete ss.marginRight;
	delete ss.width;
	delete ss.height;
	return ss;
};

const legal = {
	'ALABAMA': true,
	'AL': true,
	'ALASKA': true,
	'AK': true,
	'ARIZONA': true,
	'AZ': true,
	'ARKANSAS': true,
	'AR': true,
	'CALIFORNIA': true,
	'CA': true,
	'COLORADO': true,
	'CO': true,
	'CONNECTICUT': true,
	'CT': true,
	'DELAWARE': true,
	'DE': true,
	'DISTRICT OF COLUMBIA': true,
	'DC': true,
	'FLORIDA': true,
	'FL': true,
	'GEORGIA': true,
	'GA': true,
	'HAWAII': true,
	'HI': true,
	'IDAHO': true,
	'ID': true,
	'ILLINOIS': true,
	'IL': true,
	'INDIANA': true,
	'IN': true,
	'IOWA': true,
	'IA': true,
	'KANSAS': true,
	'KS': true,
	'KENTUCKY': true,
	'KY': true,
	'LOUISIANA': true,
	'LA': true,
	'MAINE': true,
	'ME': true,
	'MARYLAND': true,
	'MD': true,
	'MASSACHUSETTS': true,
	'MA': true,
	'MICHIGAN': true,
	'MI': true,
	'MINNESOTA': true,
	'MN': true,
	'MISSISSIPPI': true,
	'MS': true,
	'MISSOURI': true,
	'MO': true,
	'MONTANA': true,
	'MT': true,
	'NEBRASKA': true,
	'NE': true,
	'NEVADA': true,
	'NV': true,
	'NEW HAMPSHIRE': true,
	'NH': true,
	'NEW JERSEY': true,
	'NJ': true,
	'NEW MEXICO': true,
	'NM': true,
	'NEW YORK': true,
	'NY': true,
	'NORTH CAROLINA': true,
	'NC': true,
	'NORTH DAKOTA': true,
	'ND': true,
	'OHIO': true,
	'OH': true,
	'OKLAHOMA': true,
	'OK': true,
	'OREGON': true,
	'OR': true,
	'PENNSYLVANIA': true,
	'PA': true,
	'RHODE ISLAND': true,
	'RI': true,
	'SOUTH CAROLINA': true,
	'SC': true,
	'SOUTH DAKOTA': true,
	'SD': true,
	'TENNESSEE': true,
	'TN': true,
	'TEXAS': true,
	'TX': true,
	'UTAH': true,
	'UT': true,
	'VERMONT': true,
	'VT': true,
	'VIRGINIA': true,
	'VA': true,
	'WASHINGTON': true,
	'WA': true,
	'WEST VIRGINIA': true,
	'WV': true,
	'WISCONSIN': true,
	'WI': true,
	'WYOMING': true,
	'WY': true,
	'NEWFOUNDLAND AND LABRADOR': true,
	'NEWFOUNDLAND': true,
	'NL': true,
	'PRINCE EDWARD ISLAND': true,
	'PEI': true,
	'PE': true,
	'NOVA SCOTIA': true,
	'NS': true,
	'NEW BRUNSWICK': true,
	'NB': true,
	'QUEBEC': true,
	'QC': true,
	'ONTARIO': true,
	'ON': true,
	'MANITOBA': true,
	'MB': true,
	'SASKATCHEWAN': true,
	'SK': true,
	'ALBERTA': true,
	'AB': true,
	'BRITISH COLUMBIA': true,
	'BC': true,
	'YUKON': true,
	'YT': true,
	'NORTHWEST TERRITORIES': true,
	'NT': true,
	'NUNAVUT': true,
	'NU': true,
};

const legalKeys = Object.keys(legal);

export const shortState = (st: string) => {
	const idx = legalKeys.indexOf(st.toUpperCase());
	if (idx == -1) {
		return st;
	}
	return legalKeys[idx + 1] || st;
};

// @ts-ignore
export const legalState = (v: any) => !legal[v?.toUpperCase()] as any;
